import { NumberType, StringNumberType, StringType } from "@app-types/css";
import { Box } from "@ui/Box";
import { ArrayHelper } from "@utils/ArrayHelper";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import styled, { css } from "styled-components";
import { GridProps } from "./types";

const repeatItems = (count: StringNumberType, size = "1fr"): StringType =>
    count && size ? `repeat(${count}, ${CssHelper.value(size)})` : null;

const repeatItemsValue = (input: NumberType | NumberType[]): StringType | StringType[] => {
    if (ArrayHelper.isArray(input)) {
        return (input as NumberType[]).map((item) => repeatItems(item));
    }
    return repeatItems(input as StringNumberType);
};

export const Grid = styled(Box)<GridProps>(
    ({
        alignContent,
        alignItems,
        gridColumnCount,
        gridAutoColumns,
        gridAutoFlow,
        gridAutoRows,
        gridGap,
        rowGap,
        columnGap,
        gridTemplateAreas,
        gridTemplateColumns,
        gridTemplateRows,
        justifyContent,
        justifyItems,
        rowCount,
    }) => css`
        display: grid;

        ${Responsive.facePaintedMin({
            alignContent: CssHelper.value(alignContent),
            alignItems: CssHelper.value(alignItems),
            gridAutoColumns: !gridTemplateColumns ? CssHelper.value(gridAutoColumns) : null,
            gridAutoFlow: CssHelper.value(gridAutoFlow),
            gridAutoRows: !gridTemplateRows ? CssHelper.value(gridAutoRows) : null,
            rowGap: CssHelper.value(rowGap),
            columnGap: CssHelper.value(columnGap),
            gridGap: CssHelper.value(gridGap),
            gridTemplateAreas: CssHelper.value(gridTemplateAreas),
            gridTemplateColumns: gridTemplateColumns
                ? CssHelper.value(gridTemplateColumns)
                : repeatItemsValue(gridColumnCount),
            gridTemplateRows: gridTemplateRows ? CssHelper.value(gridTemplateRows) : repeatItemsValue(rowCount),
            justifyContent: CssHelper.value(justifyContent),
            justifyItems: CssHelper.value(justifyItems),
        })}
    `,
);
