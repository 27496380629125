import { FOCUS_VISIBLE_CLASSNAME, IS_HOVERABLE_CLASSNAME } from "@styles/constants";
import { marginMixin } from "@styles/newMixins";
import { NewClickable } from "@ui/NewClickable";
import { CssHelper } from "@utils/CssHelper";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { RootProps } from "./types";

export const Content = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        align-items: center;
        border-radius: ${withUnit(theme.borderRadius.full, "px")};
        display: flex;
        flex: 0 0 auto;
        height: ${rem(theme.size.ghostButton.radius)};
        justify-content: center;
        margin: -${rem((theme.size.ghostButton.radius - theme.size.ghostButton.icon) / 2)};
        text-align: center;
        transform: translateZ(0);
        transition: ${CssHelper.transition("backgroundColor")};
        width: ${rem(theme.size.ghostButton.radius)};
    `,
);

export const Root = styled(NewClickable)<RootProps>(
    ({ $mb, $ml, $mr, $mt, $light, $variant, theme }) => css`
        border-radius: ${withUnit(theme.borderRadius.full, "px")};
        display: inline-flex;
        flex: 0 0 auto;
        height: ${rem(theme.size.ghostButton.icon)};
        position: relative;
        width: ${rem(theme.size.ghostButton.icon)};

        ${marginMixin({ mb: $mb, ml: $ml, mr: $mr, mt: $mt })};

        ${Content} {
            color: ${$variant || $light ? theme.color.ghostButton.text($variant, $light) : null};
        }

        &:disabled,
        &[aria-disabled="true"] {
            opacity: 0.3;
            pointer-events: none;
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                ${Content} {
                    background-color: ${theme.color.ghostButton.background($light)[1]};
                    color: ${$variant ? theme.color.ghostButton.text($variant, $light) : null};
                }
            }
        }

        &:focus {
            outline-style: none;
        }

        &.${FOCUS_VISIBLE_CLASSNAME} {
            z-index: 1;

            ${Content} {
                box-shadow: ${theme.shadow.buttonFocus};
            }
        }
    `,
);
