import { Box } from "@ui/Box";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import { getMobileBoxMargins } from "@utils/styling";
import { withUnit } from "@uxf_base/utils/styling";
import styled, { css } from "styled-components";
import { IPaperProps } from "./types";

export const Paper = styled(Box)<IPaperProps>(
    ({ border, mobileBoxMargins, borderColor, theme }) => css`
        ${mobileBoxMargins ? getMobileBoxMargins(theme, mobileBoxMargins) : null}

        ${Responsive.facePaintedMin({
            border: CssHelper.valueFromBoolean(
                border,
                withUnit(theme.border.default, "px") + " solid " + CssHelper.themePaletteValue(borderColor),
                "none",
            ),
        })}
    `,
);

Paper.defaultProps = {
    backgroundColor: "white",
    border: true,
    borderRadius: "default",
    borderColor: "border",
    mobileBoxMargins: true,
};
