import { Box } from "@ui/Box";
import { getCssProp, withUnit } from "@uxf_base/utils/styling";
import styled, { css } from "styled-components";
import { IRootProps, IStyledImageProps } from "./types";

export const Root = styled(Box)<IRootProps>(
    ({ aspectRatio, height, width }) => css`
        height: ${height ? getCssProp(height) : "auto"};
        max-width: ${width ? getCssProp(width) : null};
        position: relative;
        width: 100%;

        &::before {
            content: "";
            display: block;
            overflow: hidden;
            padding-top: ${aspectRatio ? withUnit(1 / aspectRatio, "%") : "100%"};
            width: 100%;
        }
    `,
);

export const StyledImage = styled("img")<IStyledImageProps>(
    ({ objectFit = "cover" }) => css`
        height: 100%;
        left: 0;
        object-fit: ${objectFit ? objectFit : null};
        object-position: center;
        position: absolute;
        top: 0;
        width: 100%;
    `,
);
