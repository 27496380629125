import { IconSizeEnum } from "@config/icons";
import { Badge } from "@ui/Badge";
import { Icon } from "@ui/Icon";
import { forwardRef } from "react";
import { Content, Root } from "./styles";
import { NewGhostButtonProps } from "./types";

export const NewGhostButton = forwardRef<HTMLAnchorElement, NewGhostButtonProps>((props, ref) => {
    const {
        badge,
        badgeProps,
        light,
        iconName,
        iconLabel,
        iconSize = IconSizeEnum.large,
        mb,
        ml,
        mr,
        mt,
        shadow,
        variant,
        ...restProps
    } = props;

    return (
        <Root
            ref={ref}
            $light={light}
            $mb={mb}
            $ml={ml}
            $mr={mr}
            $mt={mt}
            $shadow={shadow}
            $variant={variant}
            {...restProps}
        >
            <Content>
                <Icon aria-label={iconLabel} name={iconName} size={iconSize} />
            </Content>
            {!!badge && (
                <Badge position="top" {...badgeProps}>
                    {badge}
                </Badge>
            )}
        </Root>
    );
});

NewGhostButton.displayName = "NewGhostButton";
