import {
    DISABLED_OPACITY,
    FOCUS_VISIBLE_CLASSNAME,
    IS_HOVERABLE_CLASSNAME,
    LINK_HOVER_OPACITY,
} from "@styles/constants";
import {
    ResponsiveHiddenMixinProps,
    ResponsiveNoWrapMixinProps,
    ResponsiveSrOnlyMixinProps,
    ResponsiveTruncateMixinProps,
    marginMixin,
    responsiveHiddenMixin,
    responsiveNoWrapMixin,
    responsiveSrOnlyMixin,
    responsiveTruncateMixin,
} from "@styles/newMixins";
import { NewClickable } from "@ui/NewClickable";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import { getColor } from "@utils/styling";
import styled, { css } from "styled-components";
import { LinkProps } from "./types";

export const Link = styled(
    (
        { truncate, textColor, textFontWeight, textVariant, underline, underlineColor, ...restProps }: LinkProps, // eslint-disable-line @typescript-eslint/no-unused-vars
    ) => <NewClickable {...restProps} />,
)<LinkProps>(
    ({
        mb,
        ml,
        mr,
        mt,
        noWrap,
        permanentlyHidden,
        textFontWeight,
        textVariant,
        truncate,
        visuallyHidden,
        textColor,
        underline,
        underlineColor,
        theme,
    }) => css`
        color: ${textColor ? getColor(theme, textColor) : null};
        text-decoration: none;
        transition: ${CssHelper.transition("color")}, ${CssHelper.transition("opacity")},
            ${CssHelper.transition("textDecorationColor")};
        overflow-wrap: break-word;
        word-break: break-word;

        &.${IS_HOVERABLE_CLASSNAME} {
            cursor: pointer;
            text-decoration: ${underline ? "underline" : null};
            text-decoration-color: ${underlineColor ? theme.color.palette[underlineColor] : null};

            &:hover,
            &:active {
                color: ${textColor ? getColor(theme, textColor) : null};
                opacity: ${LINK_HOVER_OPACITY};
            }
        }

        &.${FOCUS_VISIBLE_CLASSNAME} {
            box-shadow: ${underline ? theme.shadow.buttonFocus : null};
            text-decoration: underline;
        }

        ${marginMixin({ mb, ml, mr, mt })};

        ${Responsive.facePaintedMin({
            fontSize: CssHelper.themeTextVariantFontSizeValue(textVariant),
            fontWeight: CssHelper.themeFontWeightValue(textFontWeight),
            lineHeight: CssHelper.themeTextVariantLineHeightValue(textVariant),
        })}

        ${typeof truncate === "object"
            ? responsiveTruncateMixin(truncate as ResponsiveTruncateMixinProps)
            : truncate
              ? responsiveTruncateMixin({ condition: truncate })
              : null};

        ${typeof noWrap === "object"
            ? responsiveNoWrapMixin(noWrap as ResponsiveNoWrapMixinProps)
            : noWrap
              ? responsiveNoWrapMixin({ condition: noWrap })
              : null};

        &:focus {
            outline-style: none;
        }

        &[aria-disabled="true"] {
            opacity: ${DISABLED_OPACITY};
            outline-style: none;
            pointer-events: none;
        }

        &[aria-busy="true"] {
            opacity: ${DISABLED_OPACITY};
            pointer-events: none;
        }

        && {
            ${typeof visuallyHidden === "object"
                ? responsiveSrOnlyMixin(visuallyHidden as ResponsiveSrOnlyMixinProps)
                : visuallyHidden
                  ? responsiveSrOnlyMixin({ condition: visuallyHidden })
                  : null}

            ${typeof permanentlyHidden === "object"
                ? responsiveHiddenMixin(permanentlyHidden as ResponsiveHiddenMixinProps)
                : permanentlyHidden
                  ? responsiveHiddenMixin({ condition: permanentlyHidden })
                  : null};
        }
    `,
);
