import { Box } from "@ui/Box";
import { ForwardLink } from "@ui/ForwardLink";
import { TextCaption, TextH5 } from "@ui/Text";
import { FC } from "react";
import { useTheme } from "styled-components";
import { Content, Footer, Header, Root } from "./styles";
import { IWidgetProps } from "./types";

export const Widget: FC<IWidgetProps> = (props) => {
    const theme = useTheme();
    const {
        actionComponent,
        children,
        contentHeight,
        contentProps,
        footer,
        forwardLink,
        forwardLinkProps,
        hiddenHeader,
        hiddenOnDesktop,
        hiddenOnMobile,
        subTitle,
        title,
        titleElement,
        ...restProps
    } = props;
    return (
        <Root
            permanentlyHidden={
                hiddenOnDesktop && hiddenOnMobile
                    ? true
                    : hiddenOnMobile
                      ? { condition: true, to: "xl" }
                      : hiddenOnDesktop
                        ? { condition: true, from: "xl" }
                        : undefined
            }
            {...restProps}
        >
            {!!title && (
                <Header as="header" visuallyHidden={hiddenHeader}>
                    <Box>
                        <TextH5 as={titleElement || "h5"}>{title}</TextH5>
                        {subTitle && <TextCaption textColor="textMuted">{subTitle}</TextCaption>}
                    </Box>
                    {!!forwardLink && (
                        <ForwardLink ml={theme.legacySpacing(1.5)} {...forwardLinkProps}>
                            {forwardLink}
                        </ForwardLink>
                    )}
                    {!!actionComponent && actionComponent}
                </Header>
            )}
            <Content maxHeight={contentHeight} {...contentProps}>
                {children}
            </Content>
            {!!footer && <Footer>{footer}</Footer>}
        </Root>
    );
};
