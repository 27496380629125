import { Badge } from "@ui/Badge";
import { Circle } from "@ui/Circle";
import { Loader } from "@ui/Loader/Loader";
import { Text } from "@ui/Text";
import { UrlHelper } from "@utils/UrlHelper";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { useTheme } from "styled-components";
import { Image, Root } from "./styles";
import { IAvatarProps } from "./types";

const AvatarRenderFn: ForwardRefRenderFunction<any, IAvatarProps> = (props, ref) => {
    const theme = useTheme();
    const {
        badge,
        badgeProps,
        objectFit,
        placeholder = true,
        placeholderProps,
        size,
        squircle,
        src,
        title,
        initials,
        ...restProps
    } = props;
    const placeholderVariant = placeholderProps?.variant ?? "primary";

    const imageSize = theme.size.circle[size].radius * 2;
    const imageSource = typeof src === "string" ? src : UrlHelper.getImageUrl(src, imageSize, imageSize);

    return (
        <Root
            dummyLinkElement="div"
            innerComponent="div"
            innerComponentProps={{
                className: "avatar",
            }}
            hasSrc={!!src}
            loaderComponent={
                <Loader
                    absolute
                    borderRadius="inherit"
                    color={placeholderVariant}
                    style={{ backgroundColor: theme.color.circle.light.background(placeholderVariant)[0] }}
                />
            }
            placeholderProps={{ className: "circle", variant: placeholderVariant, ...placeholderProps }}
            ref={ref}
            size={size}
            squircle={squircle}
            title={title}
            {...restProps}
        >
            {src ? (
                <Image
                    alt={title}
                    objectFit={objectFit}
                    role={!title ? "none" : undefined}
                    size={size}
                    squircle={squircle}
                    src={imageSource ?? undefined}
                    title={title}
                />
            ) : (
                placeholder && (
                    <Circle
                        className="circle"
                        name={placeholderProps?.name ? placeholderProps.name : squircle ? "blockOfFlats" : "user"}
                        size={size}
                        squircle={squircle}
                        variant={placeholderVariant}
                        title={title}
                        {...placeholderProps}
                    >
                        {!!initials && (
                            <Text textLetterSpacing={0.5} textFontSize={16}>
                                {initials.toUpperCase()}
                            </Text>
                        )}
                    </Circle>
                )
            )}
            {!!badge && (
                <Badge position="top" {...badgeProps}>
                    {badge}
                </Badge>
            )}
        </Root>
    );
};

export const Avatar = forwardRef<any, IAvatarProps>(AvatarRenderFn);

Avatar.displayName = "Avatar";
