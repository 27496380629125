import { FC } from "react";
import { Root, StyledImage } from "./styles";
import { IImageProps } from "./types";

export const Image: FC<IImageProps> = (props) => {
    const { fullWidth, height, imgProps, objectFit, title, src, width, ...restProps } = props;
    return (
        <Root height={height} width={!fullWidth ? width : undefined} {...restProps}>
            <StyledImage objectFit={objectFit} src={src} title={title} {...imgProps} />
        </Root>
    );
};
