import { marginMixin } from "@styles/newMixins";
import { spacing } from "@uxf/styles/units/spacing";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { IDividerProps } from "./types";

export const Divider = styled("hr")<IDividerProps>(
    ({ fullWidth, lineColor, mb, mt, size, theme }) => css`
        backface-visibility: hidden;
        border-style: none;
        border-bottom: ${withUnit(size ? theme.border[size] : theme.border.default, "px")} solid
            ${lineColor ? theme.color.palette[lineColor] : theme.color.palette.border};
        display: block;
        margin: 0 ${fullWidth ? 0 : rem(spacing(2))};
        pointer-events: none;
        width: ${!fullWidth ? "calc(100% - " + rem(spacing(4)) + ")" : "100%"};

        ${marginMixin({ mb, ml: null, mr: null, mt })};
    `,
);
