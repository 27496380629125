import { FOCUS_VISIBLE_CLASSNAME } from "@styles/constants";
import { Box, IBoxProps } from "@ui/Box";
import { NewClickable, NewClickableProps } from "@ui/NewClickable";
import { Paper } from "@ui/Paper";
import { CssHelper } from "@utils/CssHelper";
import { ValueHelper } from "@utils/ValueHelper";
import { spacing } from "@uxf/styles/units/spacing";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { IRootProps } from "./types";

export const Root = styled(Paper)<IRootProps>(
    ({ disableAdjacentTopMargin }) => css`
        & + * {
            margin-top: ${!disableAdjacentTopMargin ? rem(spacing(2)) : null};
        }
    `,
);

export const Header = styled(Box)<IBoxProps>(
    ({ visuallyHidden }) => css`
        display: flex;
        align-items: flex-start;
        flex: 0 0 auto;
        justify-content: space-between;
        padding: ${rem(spacing(2))} ${rem(spacing(2))};

        && + * {
            border-top-style: ${visuallyHidden ? "none" : null};
        }
    `,
);

export const Content = styled(Box)<IBoxProps>(
    ({ maxHeight }) => css`
        overflow-y: ${maxHeight ? "auto" : null};
        position: relative;
    `,
);

export const Footer = styled("footer")<HTMLAttributes<HTMLDivElement>>`
    flex: 0 0 auto;
`;

export const AdvertClickable = styled(NewClickable)<NewClickableProps>(
    ({ theme }) => css`
        align-items: center;
        display: inline-flex;
        justify-content: center;
        max-width: ${rem(300)};
        width: 100%;

        &:focus {
            outline-style: none;
        }

        &.${FOCUS_VISIBLE_CLASSNAME} {
            box-shadow: ${theme.shadow.buttonFocus};
            z-index: 1;
        }
    `,
);

export const AdFormWrapper = styled("div")<HTMLAttributes<HTMLDivElement> & { height: number; width: number }>(
    ({ height, width }) => css`
        margin: 0 auto;
        position: relative;
        max-width: ${CssHelper.value(width)};
        width: 100%;

        &::before {
            content: "";
            display: block;
            overflow: hidden;
            padding-top: ${CssHelper.withUnit(ValueHelper.percent(height, width), "%")};
            width: 100%;
        }

        iframe {
            border-style: none;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    `,
);
