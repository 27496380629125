import { FOCUS_VISIBLE_CLASSNAME, IS_HOVERABLE_CLASSNAME, IS_HOVERED_CLASSNAME } from "@styles/constants";
import { Clickable } from "@ui/Clickable";
import { CssHelper } from "@utils/CssHelper";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { IImageProps, IRootProps } from "./types";

export const Root = styled(Clickable)<IRootProps>(
    ({ shadow, placeholderProps, size, squircle, theme }) => css`
        && {
            border-radius: ${squircle ? rem(theme.borderRadius.squircle) : withUnit(theme.borderRadius.full, "px")};
            display: block;
            flex: 0 0 auto;
            height: ${rem(theme.size.circle[size].radius)};
            width: ${rem(theme.size.circle[size].radius)};

            & > .avatar {
                border-radius: ${squircle ? rem(theme.borderRadius.squircle) : withUnit(theme.borderRadius.full, "px")};
                box-shadow: ${shadow ? theme.shadow.avatar : null};
                display: block;
                flex: 0 0 auto;
                height: ${rem(theme.size.circle[size].radius)};
                position: relative;
                width: ${rem(theme.size.circle[size].radius)};

                & > .loader-component ~ * {
                    opacity: 0;
                }
            }

            & .circle {
                transition: ${CssHelper.transition("backgroundColor")};
            }

            &.${IS_HOVERABLE_CLASSNAME} {
                &.${IS_HOVERED_CLASSNAME}, &:hover,
                &:active {
                    & .circle {
                        background-color: ${placeholderProps?.variant
                            ? theme.color.circle.light.background(placeholderProps.variant)[1]
                            : null};
                    }
                }
            }

            &.${FOCUS_VISIBLE_CLASSNAME} {
                box-shadow: ${theme.shadow.buttonFocus};
                z-index: 1;
            }
        }
    `,
);

export const Image = styled("img")<IImageProps>(
    ({ objectFit = "cover", size, squircle, theme }) => css`
        background-color: ${theme.color.palette.white};
        border-radius: ${squircle ? rem(theme.borderRadius.squircle) : withUnit(theme.borderRadius.full, "px")};
        display: block;
        height: ${rem(theme.size.circle[size].radius)};
        object-fit: ${objectFit ? objectFit : null};
        object-position: center;
        overflow: hidden;
        width: ${rem(theme.size.circle[size].radius)};
    `,
);
