import { IconSizeEnum } from "@config/icons";
import { FlexBox } from "@ui/FlexBox";
import { Icon } from "@ui/Icon";
import { NoWrap, Truncate } from "@ui/Text";
import { FC } from "react";
import { StyledLink } from "./styles";
import { BackLinkProps, ForwardLinkProps } from "./types";

export const ForwardLink: FC<ForwardLinkProps> = (props) => {
    const { children, ...restProps } = props;

    return (
        <StyledLink textColor="primary" textFontWeight="medium" textVariant="medium" {...restProps}>
            <FlexBox alignItems="center" as="span">
                <NoWrap>{children}</NoWrap>
                <Icon name="arrowRight" size={IconSizeEnum.small} />
            </FlexBox>
        </StyledLink>
    );
};

export const BackLink: FC<BackLinkProps> = (props) => {
    const { children, ...restProps } = props;

    return (
        <StyledLink textColor="primary" textFontWeight="medium" textVariant="medium" {...restProps}>
            <FlexBox alignItems="center" flexShrink="0" boxWidth="auto">
                <Icon name="arrowLeft" size={IconSizeEnum.small} />
            </FlexBox>
            <Truncate>{children}</Truncate>
        </StyledLink>
    );
};
