import { UseAnchorProps, useAnchorProps } from "@uxf/core/hooks/useAnchorProps";
import Link from "next/link";
import { AnchorHTMLAttributes, forwardRef } from "react";
import { NewClickableProps } from "./types";

interface AnchorProps extends UseAnchorProps, Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type"> {}

const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>((props, ref) => {
    const anchorProps = useAnchorProps<AnchorHTMLAttributes<HTMLAnchorElement>>(props);

    return (
        <a ref={ref} {...anchorProps}>
            {props.children}
        </a>
    );
});

Anchor.displayName = "Anchor";

export const NewClickable = forwardRef<HTMLAnchorElement, NewClickableProps>((props, ref) => {
    const { children, dataTestId, hash, href, ...restProps } = props;

    if (!href || (typeof href === "string" && (href.startsWith("http") || href.startsWith("www")))) {
        return (
            <Anchor data-testid={dataTestId} href={href} ref={ref} {...restProps}>
                {children}
            </Anchor>
        );
    }

    return (
        <Link legacyBehavior href={href + (hash ?? "")} passHref>
            <Anchor data-testid={dataTestId} ref={ref} {...restProps}>
                {children}
            </Anchor>
        </Link>
    );
});

NewClickable.displayName = "NewClickable";
