import { StringNumberType } from "@app-types/css";
import { marginMixin, paddingMixin } from "@styles/newMixins";
import { Box } from "@ui/Box";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import styled, { css } from "styled-components";
import { FlexBoxChildrenProps, FlexBoxProps } from "./types";

const childrenStyle = (props: FlexBoxChildrenProps) => css`
    min-width: 0;

    ${Responsive.facePaintedMin({
        alignContent: CssHelper.value(props.alignContent),
        alignItems: CssHelper.value(props.alignItems),
        display: CssHelper.value(props.boxDisplay),
        flexBasis: CssHelper.value(props.flexBasis),
        flexDirection: CssHelper.value(props.flexDirection),
        flexGrow: props.flexGrow ? props.flexGrow : null,
        flexShrink: props.flexShrink ? props.flexShrink : null,
        flexWrap: CssHelper.value(props.flexWrap as StringNumberType | StringNumberType[]),
        height: CssHelper.value(props.boxHeight),
        justifyContent: CssHelper.value(props.justifyContent),
        maxHeight: CssHelper.value(props.maxHeight),
        maxWidth: CssHelper.value(props.maxWidth),
        minHeight: CssHelper.value(props.minHeight),
        minWidth: CssHelper.value(props.minWidth),
        width: CssHelper.value(props.boxWidth),
    })};

    ${marginMixin({ mb: props.mb, ml: props.ml, mr: props.mr, mt: props.mt })};
    ${paddingMixin({ pb: props.pb, pl: props.pl, pr: props.pr, pt: props.pt })};
`;

export const FlexBox = styled(Box)<FlexBoxProps>(
    ({
        alignContent,
        alignItems,
        flexDirection,
        flexWrap,
        justifyContent,
        childrenFlexProps,
        gap,
        columnGap,
        rowGap,
    }) => css`
        ${Responsive.facePaintedMin({
            alignContent: CssHelper.value(alignContent),
            alignItems: CssHelper.value(alignItems),
            flexDirection: CssHelper.value(flexDirection),
            flexWrap: CssHelper.value(flexWrap),
            justifyContent: CssHelper.value(justifyContent),
            gap: CssHelper.value(gap),
            columnGap: CssHelper.value(columnGap),
            rowGap: CssHelper.value(rowGap),
        })};

        && > * {
            ${childrenFlexProps ? childrenStyle(childrenFlexProps) : null};
        }
    `,
);

FlexBox.defaultProps = {
    boxDisplay: "flex",
};
