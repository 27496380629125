import { Badge } from "@ui/Badge";
import { Icon } from "@ui/Icon";
import { TextMedium } from "@ui/Text";
import { Children, FC } from "react";
import { useTheme } from "styled-components";
import { Root } from "./styles";
import { ICircleProps } from "./types";

export const Circle: FC<ICircleProps> = (props) => {
    const theme = useTheme();
    const {
        badge,
        badgeProps,
        children,
        customIconSize,
        customIconSrcSize,
        iconColor,
        iconTitle,
        name,
        size,
        textProps,
        title,
        ...restProps
    } = props;

    return (
        <Root aria-label={title || name} size={size} {...restProps}>
            {children && Children.toArray(children).length > 0 ? (
                <TextMedium as="span" textAlign="center" {...textProps}>
                    {children}
                </TextMedium>
            ) : (
                <Icon
                    aria-label={iconTitle || name}
                    className="icon"
                    color={iconColor}
                    name={name}
                    size={customIconSrcSize || theme.size.circle[size].icon}
                    iconHeight={customIconSize}
                    iconWidth={customIconSize}
                />
            )}
            {!!badge && (
                <Badge position="top" {...badgeProps}>
                    {badge}
                </Badge>
            )}
        </Root>
    );
};

Circle.defaultProps = {
    light: true,
    variant: "primary",
};
