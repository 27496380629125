import { Divider } from "@ui/Divider";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Content, EndAdornment, Root, StartAdornment } from "./styles";
import { IListItemProps } from "./types";

const ListItemRenderFn: ForwardRefRenderFunction<any, IListItemProps> = (props, ref) => {
    const {
        divider = true,
        dividerProps,
        children,
        contentProps,
        endAdornment,
        endAdornmentProps,
        startAdornment,
        startAdornmentProps,
        ...restProps
    } = props;
    return (
        <>
            <Root fakeButton ref={ref} {...restProps}>
                {!!startAdornment && (
                    <StartAdornment verticalAlign="center" {...startAdornmentProps}>
                        {startAdornment}
                    </StartAdornment>
                )}
                <Content {...contentProps}>{children}</Content>
                {!!endAdornment && (
                    <EndAdornment verticalAlign="center" {...endAdornmentProps}>
                        {endAdornment}
                    </EndAdornment>
                )}
            </Root>
            <Divider hidden={!divider} as="span" className="divider" {...dividerProps} />
        </>
    );
};

export const ListItem = forwardRef<any, IListItemProps>(ListItemRenderFn);

ListItem.defaultProps = {
    size: "medium",
};

ListItem.displayName = "ListItem";
