import { Box } from "@ui/Box";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { IRootProps } from "./types";

export const Root = styled(Box)<IRootProps>(
    ({ light, size, shadow, squircle, variant, theme }) => css`
        align-items: center;
        background-color: ${light && variant
            ? theme.color.circle.light.background(variant)[0]
            : variant
              ? theme.color.circle.default.background(variant)[0]
              : null};
        box-shadow: ${!!shadow && !light ? theme.shadow.circle : null};
        border-radius: ${squircle
            ? withUnit(theme.borderRadius.squircle, "px")
            : withUnit(theme.borderRadius.full, "px")};
        color: ${light && variant
            ? theme.color.circle.light.text(variant)
            : theme.color.circle.default.text(variant ?? "text")};
        display: flex;
        flex: 0 0 auto;
        height: ${rem(theme.size.circle[size].radius)};
        justify-content: center;
        position: relative;
        text-align: center;
        width: ${rem(theme.size.circle[size].radius)};
    `,
);
