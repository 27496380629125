import { FOCUS_VISIBLE_CLASSNAME, IS_HOVERABLE_CLASSNAME } from "@styles/constants";
import { Clickable } from "@ui/Clickable";
import { CssHelper } from "@utils/CssHelper";
import { spacing } from "@uxf/styles/units/spacing";
import transparentize from "polished/lib/color/transparentize";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { IContentProps, IEndAdornmentProps, IRootProps, IStartAdornmentProps } from "./types";

export const Root = styled(Clickable)<IRootProps>(
    ({ disablePadding, size, verticalAlign, theme }) => css`
        && {
            display: block;
            min-height: ${size ? rem(theme.size.list[size]) : null};
            position: relative;
            text-align: left;
            width: 100%;

            & > .inner-component {
                align-items: ${verticalAlign ? verticalAlign : "center"};
                display: flex;
                justify-content: flex-start;
                min-height: inherit;
                padding: ${rem(spacing(1))} ${!disablePadding ? rem(spacing(2)) : 0};
                transition: ${CssHelper.transition("backgroundColor")};
            }

            &.${IS_HOVERABLE_CLASSNAME} {
                &:hover,
                &:active {
                    & > .inner-component {
                        background-color: ${transparentize(0.95, theme.color.palette.primary)};
                    }
                }
            }

            .divider {
                bottom: 0;
                left: 0;
                position: absolute;
                z-index: 1;
            }

            &.${FOCUS_VISIBLE_CLASSNAME} {
                & > .inner-component {
                    box-shadow: ${theme.shadow.buttonFocus};
                    position: relative;
                    z-index: 2;
                }
            }
        }
    `,
);

export const StartAdornment = styled("div")<IStartAdornmentProps>(
    ({ verticalAlign, theme, withoutMargin }) => css`
        align-items: ${verticalAlign ? verticalAlign : null};
        display: flex;
        flex: 0 0 auto;
        margin-right: ${withoutMargin ? undefined : rem(theme.legacySpacing(1.5))};
    `,
);

export const Content = styled("div")<IContentProps>(
    ({ verticalAlign }) => css`
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: ${verticalAlign ? verticalAlign : null};
        min-width: 0;
    `,
);

export const EndAdornment = styled("div")<IEndAdornmentProps>(
    ({ verticalAlign, theme }) => css`
        align-items: ${verticalAlign ? verticalAlign : null};
        display: flex;
        flex: 0 0 auto;
        margin-left: ${rem(theme.legacySpacing(1.5))};

        & > *:not(:last-child) {
            margin-right: ${rem(theme.legacySpacing(1.5))};
        }
    `,
);
